import {
  whisk_backoffice_b2c_v1_NotificationAPI_PublishSystemNotification as PublishSystemNotification,
  whisk_backoffice_b2c_v1_PublishSystemNotificationRequest as PublishSystemNotificationRequest,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

export { PublishSystemNotificationRequest };

const api = b2cGrpc();

export const publishSystemNotification = (payload: PublishSystemNotificationRequest) =>
  api(PublishSystemNotification, payload);
