import { action, makeObservable } from 'mobx';

import { CountriesStore } from 'b2b/countries';
import { DataLoader } from 'common/helpers/data-loader';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateRequired } from 'common/helpers/form/validators';
import { BaseStore, getStore } from 'common/stores';

import { publishSystemNotification } from './api';
import { Language } from './constants';

export class BlogPostNotificationsFormStore extends BaseStore {
  readonly loader = new DataLoader();
  readonly countriesStore = new CountriesStore();

  readonly form = new FormModel({
    postId: new FieldModel('').validators(validateRequired),
    countries: new FieldModel<string[]>([]),
    languages: new FieldModel<Language[]>([]),
  });

  @action storeReset = () => {
    this.loader.reset();
    this.form.reset();
  };

  @action publishSystemNotification = async () => {
    const { postId, countries, languages } = this.form.fields;
    const { success, error } = await this.loader.load(() =>
      publishSystemNotification({
        postId: postId.value,
        filters: {
          fromCountries: countries.value,
          withLanguages: languages.value,
        },
      })
    );

    if (success) {
      getStore('toast').success('Notifications were successfully sent');
      this.form.reset();
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  constructor() {
    super();

    makeObservable(this);
  }
}
