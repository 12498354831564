import { memo } from 'react';

import { PageHeader } from 'common/components/page-header';

import { NotificationsForm } from './form';

export const BlogPostNotificationsPage = memo(() => (
  <div>
    <PageHeader title="Blog post notifications" />
    <NotificationsForm />
  </div>
));
