import { memo } from 'react';

import { DashboardLayout } from 'common/components/layouts/dashboard';
import { getStore } from 'common/stores';

import { ApproveRecipePage } from './approve-recipe';
import { BanCommunityPage } from './ban-community';
import { BanRecipePage } from './ban-recipe';
import { BlockUserPage } from './block-user';
import { BlockedIndexingDomains } from './blocked-domains';
import { BlockedUrls } from './blocked-urls';
import { BlogPostNotificationsPage } from './blog-post-notifications';
import { ClaimedDomains } from './claimed-domains';
import { DeleteUserPage } from './delete-user';
import { FlaggedImageQueuePage } from './flagged-image-queue';
import { FlaggedRecipeQueuePage } from './flagged-recipe-queue';
import { FlaggedTextQueuePage } from './flagged-text-queue';
import { ModeratedEntityType } from './helpers/reported/api';
import { IndexingTool } from './indexing-tool';
import { MergeUsersPage } from './merge-users';
import { Reported } from './reported';
import { TrustedUsersPage } from './trusted-users';
import { UpdateCommunitiesPage } from './update-communities';

export const B2CPage = memo(() => {
  const { isAdmin } = getStore('user');

  return (
    <DashboardLayout
      items={[
        {
          path: '/update-communities',
          icon: 'properties',
          label: 'Update communities',
          component: UpdateCommunitiesPage,
        },
        { path: '/approve-recipe', icon: 'tick-circle', label: 'Approve recipe', component: ApproveRecipePage },
        { path: '/ban-community', icon: 'disable', label: 'Ban community', component: BanCommunityPage },
        { path: '/ban-recipe', icon: 'disable', label: 'Ban recipe', component: BanRecipePage },
        { path: '/trusted-users', icon: 'user', label: 'Trusted users', component: TrustedUsersPage },
        {
          path: '/flagged-recipe-queue',
          icon: 'list',
          label: 'Flagged Recipe Queue',
          component: FlaggedRecipeQueuePage,
        },
        { path: '/flagged-image-queue', icon: 'list', label: 'Flagged Image Queue', component: FlaggedImageQueuePage },
        { path: '/flagged-text-queue', icon: 'list', label: 'Flagged Text Queue', component: FlaggedTextQueuePage },
        {
          path: '/recipe-reviews',
          icon: 'thumbs-up',
          label: 'Recipe Reviews',
          component: () => (
            <Reported title="Recipe Reviews" entity={ModeratedEntityType.MODERATED_ENTITY_TYPE_RECIPE_REVIEW} />
          ),
        },
        {
          path: '/recipe-reviews-replies',
          icon: 'thumbs-up',
          label: 'Recipe Review Replies',
          component: () => (
            <Reported
              title="Recipe Reviews Replies"
              entity={ModeratedEntityType.MODERATED_ENTITY_TYPE_RECIPE_REVIEW_REPLY}
            />
          ),
        },
        {
          path: '/reported-posts',
          icon: 'thumbs-up',
          label: 'Reported Posts',
          component: () => <Reported title="Reported Posts" entity={ModeratedEntityType.MODERATED_ENTITY_TYPE_POST} />,
        },
        {
          path: '/post-reply',
          icon: 'thumbs-up',
          label: 'Reported Post Replies',
          component: () => (
            <Reported title="Reported Post replies" entity={ModeratedEntityType.MODERATED_ENTITY_TYPE_POST_REPLY} />
          ),
        },
        {
          path: '/conversations-post',
          icon: 'comment',
          label: 'Conversations Post',
          component: () => (
            <Reported
              title="Conversation Post"
              entity={ModeratedEntityType.MODERATED_ENTITY_TYPE_COMMUNITY_CONVERSATION}
            />
          ),
        },
        {
          path: '/conversations-reply',
          icon: 'comment',
          label: 'Conversations Reply',
          component: () => (
            <Reported
              title="Conversation Reply"
              entity={ModeratedEntityType.MODERATED_ENTITY_TYPE_COMMUNITY_CONVERSATION_REPLY}
            />
          ),
        },
        {
          path: '/blocked-urls',
          icon: 'list',
          label: 'Blocked Urls',
          component: () => <BlockedUrls />,
        },
        {
          path: '/blocked-domains',
          icon: 'list',
          label: 'Blocked Indexing Domains',
          component: () => <BlockedIndexingDomains />,
        },
        isAdmin ? { path: '/delete-user', icon: 'delete', label: 'Delete user', component: DeleteUserPage } : undefined,
        isAdmin ? { path: '/block-user', icon: 'issue', label: 'Block user', component: BlockUserPage } : undefined,
        isAdmin
          ? { path: '/merge-users', icon: 'merge-columns', label: 'Merge users', component: MergeUsersPage }
          : undefined,
        {
          path: '/claimed-domains',
          icon: 'list-detail-view',
          label: 'Claimed Domains',
          component: () => <ClaimedDomains />,
        },
        { path: '/indexing-tool', icon: 'list', label: 'Indexing Tool', component: IndexingTool },
        {
          path: '/blog-post-notifications',
          icon: 'send-message',
          label: 'Blog Post Notifications',
          component: BlogPostNotificationsPage,
        },
      ]}
    />
  );
});
