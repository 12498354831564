import { FormGroup } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { FieldCountries } from 'b2b/countries';
import { Card } from 'common/components/card';
import { FieldInput } from 'common/components/fields/field-input';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { getFieldFormGroupProps } from 'common/components/form/helpers';
import { useStore } from 'common/hooks/use-store';
import { clsMb30, clsMt30 } from 'common/styles/margin-padding';

import { LanguagesMultiselect } from './languages-multiselect';
import { BlogPostNotificationsFormStore } from './store';

export const NotificationsForm = observer(() => {
  const { form, loader, countriesStore, publishSystemNotification } = useStore(
    () => new BlogPostNotificationsFormStore()
  );

  useEffect(() => {
    void countriesStore.loadCountries();
  }, [countriesStore]);

  return (
    <Card>
      <Form form={form} loader={loader} onSubmit={publishSystemNotification} errorTitle="Failed to send request">
        <div className={clsMb30}>
          <FieldInput field={form.fields.postId} label="Post ID" placeholder="e.g. 6cabec8c4b9245d993d641b58a0bb0b9" />
        </div>
        <FieldCountries
          label="Countries, all by default"
          field={form.fields.countries}
          countries={countriesStore.countries}
          tagColumns={4}
        />
        <FormGroup {...getFieldFormGroupProps(form.fields.languages)} label="Languages, all by default">
          <LanguagesMultiselect field={form.fields.languages} tagColumns={4} />
        </FormGroup>

        <ButtonSubmit className={clsMt30} text="Publish notification" large />
      </Form>
    </Card>
  );
});
