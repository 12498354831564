import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { CountryFlag } from 'b2b/countries/country-flag';
import { getFieldControlProps } from 'common/components/form/helpers';
import { Multiselect, MultiselectItem } from 'common/components/form/multi-select';
import { FieldModel } from 'common/helpers/form';
import { stringCompareAsc } from 'common/helpers/sort';

import { Language, languageOptions } from './constants';

export interface CountriesMultiselectProps {
  field: FieldModel<Language[]>;
  tagColumns?: number;
  placeholder?: string;
}

export const LanguagesMultiselect = observer(
  ({ field, tagColumns, placeholder = 'Search languages' }: CountriesMultiselectProps) => {
    const items: MultiselectItem<Language>[] = useMemo(
      () =>
        languageOptions
          .map((language) => ({
            value: language.value,
            label: language.label,
            icon: <CountryFlag code={language.icon} />,
            comment: language.value,
          }))
          .sort((a, b) => stringCompareAsc(a.label, b.label)),
      []
    );

    return (
      <Multiselect
        {...getFieldControlProps(field)}
        items={items}
        getValueKey={(item) => item}
        placeholder={placeholder}
        tagColumns={tagColumns}
        large
        filterByQuery
        minimal
      />
    );
  }
);
