import { isPresent } from '@whisklabs/typeguards';
import { action, makeObservable } from 'mobx';

import { DataLoader } from 'common/helpers/data-loader';
import { FieldModel, FormModel } from 'common/helpers/form';
import { BaseStore, getStore } from 'common/stores';

import { BatchAddRulesRequest, batchAddRules, downloadRules as downloadRulesApi } from '../../../api';
import { ControlStore } from '../../settings/store';

const CSV_FILE_TYPE = 'text/csv;charset=utf-8;';

export class FileFormStore extends BaseStore {
  readonly loader = new DataLoader();

  readonly form = new FormModel({
    file: new FieldModel<Blob | undefined>(undefined),
  });

  constructor(private readonly controlStore: ControlStore) {
    super();
    makeObservable(this);
  }

  @action
  storeReset = () => {
    this.loader.reset();

    this.form.reset();
  };

  @action
  submit = ({ file }: { file: Blob | undefined }) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target?.result as string | undefined | null;
      if (isPresent(text)) {
        void this.addRules({
          ruleType: this.controlStore.ruleType,
          recordType: this.controlStore.recordType,
          csv: text,
          replaceRecords: false,
        });
      }
    };

    if (isPresent(file)) {
      reader.readAsText(file);
    }
  };

  @action
  addRules = async (payload: BatchAddRulesRequest) => {
    const { success, error } = await this.loader.load(() => batchAddRules(payload));

    if (success) {
      getStore('toast').success('Record Added');
      this.form.reset();
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action
  downloadRules = async () => {
    const { success, error, data } = await this.loader.load(() =>
      downloadRulesApi({
        ruleType: this.controlStore.ruleType,
        recordType: this.controlStore.recordType,
      })
    );

    if (success && isPresent(data)) {
      const blob = new Blob([data.csv], {
        type: CSV_FILE_TYPE,
      });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'index_list.csv';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
