import { map } from 'lodash';

export enum Language {
  EN = 'en',
  DE = 'de',
  KO = 'ko',
  ES = 'es',
  FR = 'fr',
  HI = 'hi',
  IT = 'it',
}

const IconLanguage: Record<Language, string> = {
  [Language.DE]: 'de',
  [Language.EN]: 'us',
  [Language.ES]: 'es',
  [Language.FR]: 'fr',
  [Language.HI]: 'in',
  [Language.IT]: 'it',
  [Language.KO]: 'kr',
};

const LanguageName: Record<string, string> = {
  [Language.DE]: 'German',
  [Language.EN]: 'English',
  [Language.ES]: 'Spanish',
  [Language.FR]: 'French',
  [Language.HI]: 'Hindi',
  [Language.IT]: 'Italian',
  [Language.KO]: 'Korean',
};

interface LanguageOption {
  label: string;
  value: Language;
  icon?: string;
}

export const languageOptions = map(Language, (key, value) => ({
  label: LanguageName[key],
  value,
  icon: IconLanguage[key],
})) as LanguageOption[];
